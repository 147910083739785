import React, { useState, useEffect, useRef } from 'react'
import { PiDot, PiPaperPlaneTilt, PiX } from 'react-icons/pi'
import notificationSound from './mensagem.mp3'

const Chat = ({ setShowWidget }) => {
  const [mensagens, setMensagens] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [, setMsgPergunta] = useState(false)
  const [digitando, setDigitando] = useState(false)
  const [token] = useState(localStorage.getItem('token'))

  const mensagensEndRef = useRef(null)
  const audio = new Audio(notificationSound)

  useEffect(() => {
    const historicoMensagens = localStorage.getItem('historicoMensagens')
    if (historicoMensagens) {
      setMensagens(JSON.parse(historicoMensagens))
      setMsgPergunta(true)
    } else {
      enviarMensagemInicial("Como criar uma conta?")
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    localStorage.setItem('historicoMensagens', JSON.stringify(mensagens))
    if (mensagensEndRef.current) {
      mensagensEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [mensagens])

  const enviarMensagemInicial = async (mensagemInicial) => {
    try {
      // Adiciona a mensagem inicial ao estado antes do envio
      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Você', texto: mensagemInicial, hora: new Date(), status: 'enviado' },
      ])

      setDigitando(true)

      const response = await fetch(`https://atendepramim.com:8082/api/chat/empresa/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ pergunta: inputValue }),
      })

      if (!response.ok) {
        throw new Error(`Erro de rede: ${response.status} ${response.statusText}`)
      }

      const data = await response.json()

      if (!data.resposta) {
        throw new Error('Resposta inválida da API')
      }

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Assistente', texto: data.resposta, hora: new Date(), status: 'lido' },
      ])

    } catch (error) {
      console.error('Erro ao enviar mensagem:', error)

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Assistente', texto: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.', hora: new Date(), status: 'lido' },
      ])
    } finally {
      audio.play()
      setDigitando(false)
      setMsgPergunta(true)
    }
  }

  const linkClicavel = (text) => {
    const urlRegex = /https?:\/\/[^\s]+/g
    return text.split(urlRegex).reduce((prev, current, i) => {
      const match = text.match(urlRegex)
      if (!match) return prev.concat(current)
      return prev.concat(
        <React.Fragment key={i}>
          {current}
          <a href={match[i]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {match[i]}
          </a>
        </React.Fragment>
      )
    }, [])
  }

  const atualizarStatusMensagem = (index, novoStatus) => {
    setMensagens((mensagensAnteriores) => {
      const novasMensagens = [...mensagensAnteriores]
      novasMensagens[index].status = novoStatus
      return novasMensagens
    })
  }

  const handleSendMensagem = async () => {
    const novaMensagem = {
      remetente: 'Você',
      texto: inputValue,
      hora: new Date(),
      status: 'enviado',
    }
    setMensagens([...mensagens, novaMensagem])
    setInputValue('')
    setDigitando(true)

    const indexUltimaMensagem = mensagens.length

    try {
      const response = await fetch('https://atendepramim.com:8082/api/chat/empresa/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ pergunta: inputValue }),
      })

      if (!response.ok) {
        throw new Error(`Erro de rede: ${response.status} ${response.statusText}`)
      }

      const data = await response.json()

      if (!data.resposta) {
        throw new Error('Resposta inválida da API')
      }

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Assistente', texto: data.resposta, hora: new Date(), status: 'lido' },
      ])

    } catch (error) {
      console.error('Erro ao enviar mensagem:', error)

      setMensagens((prevMensagens) => [
        ...prevMensagens,
        { remetente: 'Assistente', texto: 'Desculpe, ocorreu um erro ao processar sua mensagem, tente novamente mais tarde.', hora: new Date(), status: 'lido' },
      ])
    } finally {
      audio.play()
      atualizarStatusMensagem(indexUltimaMensagem, 'lido')
      setDigitando(false)
    }
  }

  return (
    <div className="relative flex flex-col h-[77vh] w-[27vw] justify-center 2xl:-ml-16 -ml-1 shadow-2xl">
      <div className='absolute bg-[#006400] rounded-t-xl top-0 h-14 w-full'>
          <div className='flex justify-end w-full p-2'>
              <PiX color='white' className='cursor-pointer' size={30} onClick={() => {setShowWidget(false)}}/>
          </div>
      </div>
      <div className="flex-1 overflow-y-auto p-4 bg-white h-full mt-14 shadow-2xl">
        {mensagens.map((mensagem, index) => (
          <div key={index} className={`flex mb-2 ${mensagem.remetente === 'Você' ? 'justify-end' : 'justify-start'}`}>
            <div className="flex items-start">
              <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] ${mensagem.remetente === 'Assistente' ? 'bg-[#bfbebd] ml-2' : 'bg-[#5ca55d] mr-2'} ${mensagem.remetente === 'Você' ? '' : 'text-left'}`} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                <div style={{ whiteSpace: 'pre-wrap' }}>{linkClicavel(mensagem.texto)}</div>
                <div className={`${mensagem.remetente === 'Assistente' ? 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] border-r-[#bfbebd] rotate-[-40deg] bottom-[-5px] -ml-[15px] text-black' : 'absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] border-l-[#5ca55d] mt-2 right-0 rotate-[40deg] bottom-[-5px] -mr-[7px]' }`} />
              </div>
            </div>
          </div>
        ))}
        {digitando && (
          <div className="flex mb-2 justify-start">
            <div className="flex items-start">
              <div className="p-2 rounded-md text-sm bg-chat_cor_1 text-left">
                <div className="font-bold"></div>
                <div className='flex -mb-6 -mt-4'>
                  <PiDot color='white' size={60} className='animate-bounce -mr-10 -ml-5' />
                  <PiDot color='white' size={60} className='animate-bounce -mr-10' style={{ animationDelay: '0.2s' }} />
                  <PiDot color='white' size={60} className='animate-bounce -mr-4' style={{ animationDelay: '0.4s' }} />
                </div>
              </div>
            </div>
          </div>
        )}
        <div ref={mensagensEndRef} />
      </div>
      <div className="p-3 flex flex-col w-full bg-white rounded-b-xl">
        <div className='flex'>
          <textarea
            type="text"
            placeholder="Digite uma mensagem..."
            className="w-[87%] mr-8 p-2 pr-[34px] rounded-2xl bg-white  border border-gray-300 mb-[10px] resize-none focus:outline-none"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                handleSendMensagem()
              }
            }}
            rows={1}
          />
          <PiPaperPlaneTilt size={40} color='white' className="absolute right-0 mr-2 bg-[#228b22] p-[4px] rounded-full" onClick={handleSendMensagem} />
        </div>
      </div>
    </div>
  )
}

export default Chat
