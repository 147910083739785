import React, { useEffect, useState } from 'react'
import Estilos from '../ui/estilos'
import FotoPerfil from '../ui/fotoPerfil'
import { isMobile } from 'react-device-detect'

const Perfil = () => {
  const token = localStorage.getItem('token')
  const [fotoPerfil, setFotoPerfil] = useState(null)
  const [blobImagem, setBlobImagem] = useState(null)
  const [showRedefinirSenha, setShowRedefinirSenha] = useState(false)
  const [showDadosConta, setShowDadosConta] = useState(false)
  const [showRedesRelationadas, setShowRedesRelationadas] = useState(false)
  const [erroSenha, setErroSenha] = useState(0)
  const dados = JSON.parse(localStorage.getItem('conta'))

  const [senha, setSenha] = useState('')
  const [confirma, setConfirma] = useState('')
  const [atualSenha, setAtualSenha] = useState('')

  const [whatsApp, setWhatsApp] = useState('')
  const [face, setFace] = useState('')
  const [insta, setInsta] = useState('')

  const [username, setUsername] = useState(dados.username)
  const [email, setEmail] = useState(dados.email)

  const [idEstilo, setIdEstilo] = useState({ id_estilo: dados.id_estilo })

  const handleImagemUpload = (imagemCortada, blobImagemCortada) => {
    setFotoPerfil(imagemCortada)
    setBlobImagem(blobImagemCortada)
  }

  const closeModal = () => {
    setShowDadosConta(false)
    setShowRedesRelationadas(false)
    setShowRedefinirSenha(false)
  }

  useEffect(() => {
    if(senha !== confirma || (senha.length < 8 && senha.length !== 0 )){
      setErroSenha(1)
    }else{
      setErroSenha(0)
    }

    if(atualSenha.length < 8 && atualSenha.length !== 0 ){
      setErroSenha(1)
    }else{
      setErroSenha(0)
    }
  })

  useEffect(() => {
    if(idEstilo.id_estilo !== dados.id_estilo){
      const formData = new FormData()

      formData.append('id_estilo', idEstilo.id_estilo)
    
      fetch('https://atendepramim.com:8082/api/auth-usuarios/atualizar-perfil/', {
        method: 'PATCH',
        headers: {
          'Authorization': `Token ${token}`,
        },
        body: formData
      })
    }
  },[idEstilo])

  const atualizarSenha = () => {
    fetch('https://atendepramim.com:8082/api/auth-usuarios/atualizar-login/',{
      method: 'PATCH',
      headers:{
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        nova_senha: senha,
        confirma_nova_senha: confirma,
        senha_atual: atualSenha
      })
    })
  }

  const atualizarPerfil = async () => {
    try {
      let perfilAtualizado = false

      if (dados.username !== username) {
        const response = await fetch('https://atendepramim.com:8082/api/auth-usuarios/atualizar-login/', {
          method: 'PATCH',
          headers: {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            novo_username: username,
            senha_atual: atualSenha,
          }),
        })
        if (response.ok) {
          perfilAtualizado = true
        }
      }

      if (dados.email !== email) {
        const formData = new FormData()
        formData.append('email', email)
  
        const response = await fetch('https://atendepramim.com:8082/api/auth-usuarios/atualizar-perfil/', {
          method: 'PATCH',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        })
        if (response.ok) {
          perfilAtualizado = true
        }
      }

      if (blobImagem) {
        const formData = new FormData()
        formData.append('foto_perfil', blobImagem, 'perfil.png')
  
        const response = await fetch('https://atendepramim.com:8082/api/auth-usuarios/atualizar-perfil/', {
          method: 'PATCH',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        })
        if (response.ok) {
          perfilAtualizado = true
        }
      }

      if (perfilAtualizado) {
        try {
          const login = dados.username 
  
          const response = await fetch(`https://atendepramim.com:8082/api/usuarios/${login}/`, {
            method: 'GET',
            headers: {
              'Authorization': `Token ${token}`,
            },
          })
  
          if (response.ok) {
            const resposta = await response.json()
  
            // Atualiza os dados no localStorage
            localStorage.setItem('conta', JSON.stringify(resposta))
  
            const response_2 = await fetch('https://atendepramim.com:8082/api/estilos/')
            const estilos = await response_2.json()
  
            for (let i = 0; i < estilos.length; i++) {
              if (estilos[i].id_estilo === resposta.id_estilo) {
                localStorage.setItem('estiloSelecionado', JSON.stringify(estilos[i]))
                window.location.reload()
                break
              }
            }
          }
        } catch (error) {
          console.error('Erro ao buscar os dados atualizados:', error)
        }
      }
  
    } catch (error) {
      console.error('Erro ao atualizar o perfil:', error)
    }
  }
  

  const atualizarRedesSociais = () => {
    const formData = new FormData()
    if (whatsApp) formData.append('redes_sociais[whatsapp]', whatsApp)
    if (insta) formData.append('redes_sociais[instagram]', insta)
    if (face) formData.append('redes_sociais[facebook]', face)

    fetch('https://atendepramim.com:8082/api/auth-usuarios/atualizar-perfil/', {
      method: 'PATCH',
      headers: {
        'Authorization': `Token ${token}`,
      },
      body: formData
    })
      .then(response => {
        if (response.ok) {
          console.log('Redes sociais atualizadas com sucesso')
          console.log(response)
        } else {
          console.error('Erro ao atualizar redes sociais')
        }
      })
      .catch(error => {
        console.error('Erro na requisição:', error)
      })
  }

  if (isMobile) {
    return (
      <div className='w-full h-full'>
        <div className='flex flex-col'>
          <div className='flex flex-col w-full'>
            <div
              className='bg-white rounded-lg my-2 py-2 text-center text-lg font-semibold shadow-dark-bottom-right w-full h-full cursor-pointer'
              onClick={() => setShowDadosConta(true)}
            >
              Dados da conta
            </div>
            <div
              className='bg-white rounded-lg mt-2 mb-3 py-2 text-center text-lg font-semibold shadow-dark-bottom-right w-full h-full cursor-pointer'
              onClick={() => setShowRedefinirSenha(true)}
            >
              Redefinir senha
            </div>
            <div
              className='bg-white rounded-lg mt-2 mb-3 py-2 text-center text-lg font-semibold shadow-dark-bottom-right w-full h-full cursor-pointer'
              onClick={() => setShowRedesRelationadas(true)}
            >
              Redes relationadas
            </div>
          </div>

          <div className='flex flex-row items-center justify-center'>
            <Estilos 
              idEstilo={idEstilo}
              setIdEstilo={setIdEstilo}
              largura={'350px'} 
            />
          </div>
        </div>

        {showDadosConta && (
          <div className='fixed inset-0 z-20 flex items-center justify-center'>
            <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
            <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[90%] z-50'>
              <div className='flex px-8 py-6 h-full'>
                <div className='flex flex-col h-full w-full ml-5'>
                  <div className='flex items-center justify-center'>
                    <FotoPerfil onImagemUpload={handleImagemUpload} imagem={fotoPerfil} />
                  </div>

                  <h1 className='font-bold text-[18px]'>Nome de usuário</h1>
                  <input
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    className='border border-gray-400 bg-gray-100 rounded-md w-[100%] pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='Nome do empreendimento'
                  />

                  <div className='flex'>
                    <div className='flex flex-col w-full'>
                      <h1 className='font-bold text-[18px] mt-2'>Email</h1>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='border border-gray-400 bg-gray-100 rounded-md w-[95%] pl-2 py-1 h-9 resize-none focus:outline-none'
                        placeholder='Email'
                      />
                    </div>
                  </div>

                  <div className='flex '>
                    <div className='flex flex-col w-full'>
                      <h1 className='font-bold text-[18px] mt-5'>Senha Atual</h1>
                      <input
                        value={atualSenha}
                        type='password'
                        onChange={(e) => setAtualSenha(e.target.value)}
                        className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                      />
                    </div>
                  </div>
                  <div className='flex flex-col mt-3 items-end'>
                    <div onClick={() => (atualizarPerfil())} className='p-2 rounded-xl text-white text-center font-semibold bg-[#228b22] shadow-dark-bottom-right cursor-pointer'>
                      Salvar Alterações
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        )}
        {showRedefinirSenha && (
          <div className='fixed inset-0 z-20 flex items-center justify-center'>
            <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
            <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[90%] z-50'>
              <div className='flex flex-col justify-center items-center my-6 px-5 py-3 h-[100%] mx-10'>
                <h1 className='font-bold text-[20px]'>
                  Redefinir senha
                </h1>

                <div className='flex flex-col h-full w-full mt-[10%]'>
                  <h1 className='font-bold text-[15px]'>Nova Senha</h1>
                  <input
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    type='password'
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                  />

                  <h1 className='font-bold text-[15px]'>Confirma senha</h1>
                  <input
                    value={confirma}
                    onChange={(e) => setConfirma(e.target.value)}
                    type='password'
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                  />

                  <h1 className='font-bold text-[15px]'>Senha atual</h1>
                  <input
                    value={atualSenha}
                    onChange={(e) => setAtualSenha(e.target.value)}
                    type='password'
                    className='border border-gray-400 bg-gray-100 rounded-md w-full mb-4 pl-2 py-1 h-9 resize-none focus:outline-none'
                  />

                  <div className='flex flex-col'>
                    {senha === confirma && atualSenha !== senha && senha && confirma && atualSenha ? 
                    (
                      <div onClick={() => (atualizarSenha())} className='p-2 rounded-xl text-white text-center font-semibold bg-[#228b22] shadow-dark-bottom-right'>
                        Salvar Alterações
                      </div>
                    ):(
                      <div className='p-2 rounded-xl text-white text-center font-semibold bg-gray-500 shadow-dark-bottom-right'>
                        Salvar Alterações
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        </div>
        )}
        {showRedesRelationadas && (
          <div className='fixed inset-0 z-20 flex items-center justify-center'>
            <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
            <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[90%] z-50'>
              <div className='flex flex-col justify-center items-center my-6 px-5 py-3 h-[100%] mx-10'>
                <h1 className='font-bold text-[20px]'>
                  Contatos e Redes Sociais
                </h1>

                <div className='flex flex-col h-full w-full mt-[10%]'>
                  <h1 className='font-bold text-[15px]'>WhatsApp</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='(XX) X XXXX-XXXX'
                    value={whatsApp}
                    onChange={(e) => setWhatsApp(e.target.value)}
                  />

                  <h1 className='font-bold text-[15px]'>Instagram</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='@contatoinsta'
                    value={insta}
                    onChange={(e) => setInsta(e.target.value)}
                  />

                  <h1 className='font-bold text-[15px]'>Facebook</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full mb-8 pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='Facebook'
                    value={face}
                    onChange={(e) => setFace(e.target.value)}
                  />

                  <div className='flex flex-col'>
                    <div onClick={() => (atualizarRedesSociais())} className='p-2 rounded-xl text-white text-center font-semibold bg-[#228b22] shadow-dark-bottom-right'>
                      Salvar Alterações
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        )}
      </div>
    )
  }

  return (
    <div className='px-8 py-7 -mt-4 w-full h-[85vh]'>
      <div className='flex flex-col'>
        <div className='flex flex-row mx-20'>
          <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-[750px] h-full'>
            <div className='flex px-8 py-6 h-[100%]'>
              <div className='flex flex-col'>
                <h1 className='font-bold text-[22px]'>Editar Perfil</h1>
                <h1 className='font-bold text-[15px] mt-3 mb-2'>Logo</h1>
                <FotoPerfil onImagemUpload={handleImagemUpload} imagem={fotoPerfil} />
              </div>

              <div className='flex flex-col h-full w-full mt-[10%] ml-5'>
                <h1 className='font-bold text-[18px]'>Nome de usuário</h1>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  className='border border-gray-400 bg-gray-100 rounded-md w-[100%] pl-2 py-1 h-9 resize-none focus:outline-none'
                  placeholder='Nome do empreendimento'
                />

                <div className='flex'>
                  <div className='flex flex-col w-full'>
                    <h1 className='font-bold text-[18px] mt-5'>Email</h1>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                      placeholder='Email'
                      type='email'
                    />
                  </div>
                </div>

                <div className='flex gap-2 w-full items-center'>
                  <div className='flex w-[66%]'>
                    <div className='flex flex-col w-full'>
                      <h1 className='font-bold text-[18px] mt-5'>Senha Atual</h1>
                      <input
                        value={atualSenha}
                        type='password'
                        onChange={(e) => setAtualSenha(e.target.value)}
                        className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                      />
                    </div>
                  </div>
                  <div className='flex flex-col mt-12 items-end'>
                    <div onClick={() => (atualizarPerfil())} className='p-2 rounded-xl text-white text-center font-semibold bg-[#228b22] shadow-dark-bottom-right cursor-pointer'>
                      Salvar Alterações
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className='flex flex-col gap-5'>
            <div
              className='bg-white rounded-r-lg my-2 py-2 pr-4 pl-2 text-lg font-semibold shadow-dark-bottom-right cursor-pointer'
              onClick={() => setShowRedefinirSenha(true)}
            >
              Redefinir senha
            </div>
            <div
              className='bg-white rounded-r-lg my-2 py-2 pr-4 pl-2 text-lg font-semibold shadow-dark-bottom-right cursor-pointer'
              onClick={() => setShowRedesRelationadas(true)}
            >
              Contatos e Redes Sociais
            </div>
          </div>
        </div>

        <div className='flex flex-row gap-16 ml-20 mt-8'>
          <Estilos 
            idEstilo={idEstilo}
            setIdEstilo={setIdEstilo}
            largura={'550px'} 
          />
        </div>
      </div>
      {showRedefinirSenha && (
        <div className='fixed inset-0 z-20 flex items-center justify-center'>
          <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
          <div className='bg-white rounded-lg border border-gray-300  shadow-dark-bottom-right z-50'>
            <div className='flex flex-col justify-center items-center mt-4 mx-10'>
              <h1 className='font-bold text-[22px]'>Redefinir senha</h1>

              <div className='flex h-full w-full gap-4 mt-2 -mb-3'>

                <div className='flex flex-col'>                
                  <h1 className='font-bold text-[15px]'>Nova Senha</h1>
                  <input
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                    type='password'
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                  />

                  <h1 className='font-bold text-[15px]'>Confirma senha</h1>
                  <input
                    value={confirma}
                    onChange={(e) => setConfirma(e.target.value)}
                    type='password'
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                  />

                  <h1 className='font-bold text-[15px]'>Senha atual</h1>
                  <input
                    value={atualSenha}
                    onChange={(e) => setAtualSenha(e.target.value)}
                    type='password'
                    className='border border-gray-400 bg-gray-100 rounded-md w-full mb-4 pl-2 py-1 h-9 resize-none focus:outline-none'
                  />

                  <div className='flex flex-col mb-8'>
                    {senha === confirma && atualSenha !== senha && senha && confirma && atualSenha ? 
                    (
                      <div onClick={() => (atualizarSenha())} className='p-2 rounded-xl text-white text-center font-semibold bg-[#228b22] shadow-dark-bottom-right'>
                        Salvar Alterações
                      </div>
                    ):(
                      <div className='p-2 rounded-xl text-white text-center font-semibold bg-gray-500 shadow-dark-bottom-right'>
                        Salvar Alterações
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )}

      {showRedesRelationadas && (
        <div className='fixed inset-0 z-20 flex items-center justify-center'>
          <div onClick={closeModal}  className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50'/>
          <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right z-50'>
            <div className='flex flex-col justify-center items-center my-2 py-2 h-[100%] mx-10'>

            <h1 className='font-bold text-[22px]'>Redefinir redes</h1>

                <div className='flex flex-col mt-4 h-full '>
                  <h1 className='font-bold text-[15px]'>WhatsApp</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='(XX) X XXXX-XXXX'
                    value={whatsApp}
                    onChange={(e) => setWhatsApp(e.target.value)}
                  />

                  <h1 className='font-bold text-[15px] mt-3'>Instagram</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='@contatoinsta'
                    value={insta}
                    onChange={(e) => setInsta(e.target.value)}
                  />

                  <h1 className='font-bold text-[15px] mt-3'>Facebook</h1>
                  <input
                    className='border border-gray-400 bg-gray-100 rounded-md w-full mb-4 pl-2 py-1 h-9 resize-none focus:outline-none'
                    placeholder='Facebook'
                    value={face}
                    onChange={(e) => setFace(e.target.value)}
                  />

                  <div className='flex flex-col'>
                    <div onClick={() => (atualizarRedesSociais())} className='p-2 rounded-xl text-white text-center font-semibold bg-[#228b22] shadow-dark-bottom-right cursor-pointer'>
                      Salvar Alterações
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </div>
      )}
    </div>
  )
}

export default Perfil