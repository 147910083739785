import React, { useState, useRef, useEffect } from 'react'
import { PiPlusBold } from 'react-icons/pi'
import { RxPencil1 } from 'react-icons/rx'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

const FotoPerfil = ({ onImagemUpload, imagem: imagemInicial, setEstagioAssistente }) => {
  const [imagem, setImagem] = useState(null)
  const [imagemCortada, setImagemCortada] = useState(imagemInicial || null)
  const cropperRef = useRef(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (imagemInicial) {
      setImagemCortada(imagemInicial)
    }
  }, [imagemInicial])

  const onFileChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setImagem(reader.result)
        setShowModal(true)
      }
      reader.readAsDataURL(file)
    }
    // reseta o input 
    e.target.value = ''
  }

  const dataUrlConvert = (dataUrl) => {
    const array = dataUrl.split(',')
    const mime = array[0].match(/:(.*?);/)[1]
    const bstr = atob(array[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], {type: mime})
  }

  const onCrop = () => {
    const cropper = cropperRef.current.cropper
    const imagemCortada = cropper.getCroppedCanvas().toDataURL()

    const imagemCortadaBlob = dataUrlConvert(imagemCortada)

    setImagemCortada(imagemCortada)
    setShowModal(false)

    onImagemUpload(imagemCortada, imagemCortadaBlob)

    // Executa setEstagioAssistente apenas se for passado
    if (setEstagioAssistente) {
      setEstagioAssistente(1)
    }
  }

  return (
    <div className="relative w-56 h-56 mb-3">
      {imagemCortada ? (
        <div className="relative w-56 h-56">
            <img src={imagemCortada} alt="FotoPerfil" className="w-full h-full absolute rounded-full" />
            <div className='absolute bg-[#337e71] w-14 h-14 end-0 bottom-0 rounded-full cursor-pointer'/>
            <RxPencil1 onClick={() => document.getElementById('file-input').click()}  size={45} color='white' className='absolute p-1 end-[6px] bottom-1 cursor-pointer'/>
        </div>
      ) : (
        <div className="relative w-56 h-56">
            <h1 className='absolute ml-[25%] mt-[40%] text-[32px]'>Escolher</h1>
            <div className='bg-white border-2 border-gray-400 w-full h-full rounded-full'/>
            <div className='absolute bg-[#337e71] w-14 h-14 end-0 bottom-0 rounded-full cursor-pointer'/>
            <PiPlusBold onClick={() => document.getElementById('file-input').click()}  size={45} color='white' className='absolute end-[5px] bottom-[5px] cursor-pointer'/>
        </div>
      )}

      <input
        id="file-input"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={onFileChange}
      />

      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div  onClick={() => {setShowModal(false)}} className='fixed inset-0 bg-black bg-opacity-50 z-10'/>
          <div className="bg-white p-5 rounded-lg z-20">
            <Cropper
              src={imagem}
              style={{ height: 400, width: 400 }}
              aspectRatio={1}
              guides={false}
              ref={cropperRef}
              viewMode={1}
            />
            <button
              className="bg-[#228b22] text-white px-4 py-2 mt-3 w-full rounded"
              onClick={onCrop}
            >
              Recortar
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default FotoPerfil
