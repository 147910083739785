import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Função para aplicar estilos salvos no localStorage
const applySavedStyle = () => {
  const estiloSalvo = localStorage.getItem('estiloSelecionado');
  if (estiloSalvo) {
    const estilo = JSON.parse(estiloSalvo);
    document.documentElement.style.setProperty('--cor-principal', estilo.colors.cor_principal);
    document.documentElement.style.setProperty('--cor-secundaria', estilo.colors.cor_secundaria);
    document.documentElement.style.setProperty('--cor-atenuacao', estilo.colors.cor_atenuacao);
    document.documentElement.style.setProperty('--cor-fundo', estilo.colors.cor_fundo);
    document.documentElement.style.setProperty('--cor-texto', estilo.colors.cor_texto);
    document.documentElement.style.setProperty('--chat_cor_1', estilo.colors.chat_cor_1);
    document.documentElement.style.setProperty('--chat_cor_2', estilo.colors.chat_cor_2);
    document.documentElement.style.setProperty('--chat_cor_3', estilo.colors.chat_cor_3);
    document.documentElement.style.setProperty('--fundo_chat', estilo.colors.fundo_chat);
  }
};

// Aplica o estilo salvo antes da renderização do React
applySavedStyle();

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);