import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { FaSearch } from 'react-icons/fa'
import { PiArrowRight, PiPlusBold, PiTrash } from 'react-icons/pi'

const Entidades = () => {
  const [entidades, setEntidades] = useState([])
  const [termoBusca, setTermoBusca] = useState('')
  const token = localStorage.getItem('token')
  const [novaEntidade, setNovaEntidade] = useState('')
  const [novaDescricao, setNovaDescricao] = useState('')

  const fetchEntidades = async () => {
    try {
      const response = await fetch('https://atendepramim.com:8082/api/assistente-virtual/entidades/', {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`, 
        }
      })
      
      if (!response.ok) {
        throw new Error('Erro na requisição: ' + response.statusText)
      }

      const data = await response.json()
      setEntidades(data)
    } catch (error) {
      console.log('Erro ao receber entidades',error)
    }
  }

  fetchEntidades()

  const adicionarEntidade = async () => {
    if (!novaEntidade || !novaDescricao) {
      alert("Por favor, preencha ambos os campos.")
      return
    }

    const response = await fetch('https://atendepramim.com:8082/api/assistente-virtual/entidades/', {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        entidade: novaEntidade,
        descricao: novaDescricao
      })
    })

    if (!response.ok) {
      console.log('Erro ao adicionar entidade, valor já existe')
    }
    
    fetchEntidades()
    setNovaEntidade('')
    setNovaDescricao('')
  }

  const deletarEntidade = async (id) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/entidades/${id}/`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`,
        }
      })
  
      if (response.ok) {
        setEntidades((prev) =>
          prev.filter((entidade) => entidade.id_entidade !== id)
        )
      } else {
        console.error('Erro ao deletar entidade:', response.statusText)
      }
    } catch (error) {
      console.error('Erro ao deletar entidade:', error)
    }
  }

  const entidadesFiltradas = entidades.filter(({ entidade, descricao }) => 
    entidade.toLowerCase().includes(termoBusca.toLowerCase()) ||
    descricao.toLowerCase().includes(termoBusca.toLowerCase())
  )

  if(isMobile){
    return (
      <div className='w-full h-full overflow-hidden'>
        <div className='bg-white rounded-lg w-full h-full'>
          <div className='flex flex-col px-5 py-1 h-[100%]'>
            <h1 className='font-semibold text-[20px]'>Entidades</h1>
  
            <div className="flex flex-col w-full relative mt-1">
              <input
                id="termo-busca"
                className="border border-gray-400 bg-gray-100 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                placeholder="Buscar"
                value={termoBusca}
                onChange={(e) => setTermoBusca(e.target.value)}
              />
              <span className="absolute mt-[8px] ml-2 text-gray-400">
                <FaSearch size={20} />
              </span>
            </div>
  
            <div>
              <div className='flex gap-5 w-[100%] mt-1'>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Nova entidade</h1>
                  <input 
                    className="border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none" 
                    value={novaEntidade} 
                    onChange={(e) => setNovaEntidade(e.target.value)}
                  />
                </div>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Dados da entidade</h1>
                  <input 
                    className="border border-gray-400 bg-gray-100 rounded-md w-full pl-2 py-1 h-9 resize-none focus:outline-none" 
                    value={novaDescricao} 
                    onChange={(e) => setNovaDescricao(e.target.value)} 
                  />
                </div>
                
                <div className='flex items-end'>
                  <button 
                    className='bg-[#228b22] text-white w-full p-2 rounded-lg justify-center items-center'
                    onClick={adicionarEntidade}
                  >
                    <PiPlusBold size={20}/>
                  </button>
                </div>
              
              </div>
            </div>
  
            <div className='overflow-auto h-full'>
                 { entidadesFiltradas.map(({ id_entidade, entidade, descricao }) => (
                    <div key={id_entidade} className='flex flex-col w-[98%] border border-gray-400 p-2 mt-2 rounded-md'>
                      <div className='flex justify-between items-center p-3'>
                        <div className='bg-[#337e71] px-2 py-1 text-2xl font-semibold text-white rounded'>
                          <h1 className='text-[15px]'>{entidade}</h1>
                        </div>
                        <PiTrash size={30} color='red' className='cursor-pointer' onClick={() => deletarEntidade(id_entidade)} />
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[15px] ml-2 text-gray-500'>
                          {descricao}
                        </h1>
                      </div>

                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='px-8 py-7 w-full h-[85vh]'>
      <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-full h-full'>
        <div className='flex flex-col px-5 py-3 h-[100%]'>
          <h1 className='font-semibold text-[32px]'>Entidades</h1>

          <div className="flex flex-col w-full relative mt-1">
            <input
              id="termo-busca"
              className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-10 py-1 h-9 resize-none focus:outline-none"
              placeholder="Buscar"
              value={termoBusca}
              onChange={(e) => setTermoBusca(e.target.value)}
            />
            <span className="absolute mt-[8px] ml-2 text-gray-400">
              <FaSearch size={20} />
            </span>
          </div>

          <div>
            <div className='flex gap-5 w-[100%] mt-1'>
              <div className='w-full'>
                <h1>Nova entidade </h1>
                <input 
                  className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none" 
                  value={novaEntidade} 
                  onChange={(e) => setNovaEntidade(e.target.value)}
                />
              </div>
              <div className='w-full'>
                <h1>Dados da entidade</h1>
                <input 
                  className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none" 
                  value={novaDescricao} 
                  onChange={(e) => setNovaDescricao(e.target.value)} 
                />
              </div>
              <div className='w-full'>
                <h1 className='text-white'>.</h1>
                <button 
                  className='bg-[#228b22] text-white w-full p-2 rounded-lg justify-center items-center'
                  onClick={adicionarEntidade} 
                >
                  Adicionar entidade
                </button>
              </div>
            </div>
          </div>

          <div className='overflow-auto h-full'>
            {entidadesFiltradas.map(({ id_entidade, entidade, descricao }) => (
              <div key={id_entidade} className='flex w-full border border-gray-400 p-2 mt-2 rounded-md'>
                <div className='flex items-center p-3'>
                  <div className='bg-[#337e71] px-4 py-2 text-2xl font-semibold text-white rounded'>
                    <h1>{entidade}</h1>
                  </div>
                  <PiArrowRight className='ml-2' size={35}/>
                  <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                </div>
                <div className='flex w-full justify-between'>
                  <h1 className='flex items-center text-[30px] ml-2 text-gray-500'>
                    {descricao}
                  </h1>
                  <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                </div>
                <div className='flex items-center mx-3 mr-10'>
                  <PiTrash size={50} color='red' className='cursor-pointer' onClick={() => deletarEntidade(id_entidade)}/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Entidades
