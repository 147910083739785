import React from 'react'
import { GrTextAlignFull } from 'react-icons/gr'
import { PiChatsDuotone, PiCurrencyDollarSimple } from 'react-icons/pi'
import { RiSparkling2Line } from 'react-icons/ri'

function SideBar({selecionado, setSelecionado}) {
  return (
    <div className='bg-white w-full h-full shadow-2xl'>
        <div className='flex flex-col cursor-pointer'>
            <div onClick={() => setSelecionado(1)} className={`border-gray-500 border-y ${selecionado === 1 ? 'border-r  bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <h1 className='text-[20px] -mt-[2px]'>Conversas</h1>
                    <PiChatsDuotone size={30} color='gray'/>
                </div>
            </div>
            <div onClick={() => setSelecionado(2)} className={`border-gray-500 border-b ${selecionado === 2 ? 'border-r  bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <h1 className='text-[20px] -mt-[2px]'>Contextos</h1>
                    <GrTextAlignFull size={30} color='gray'/>
                </div>
            </div>
            <div onClick={() => setSelecionado(3)} className={`border-gray-500 border-b ${selecionado === 3 ? 'border-r  bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <h1 className='text-[20px] -mt-[2px]'>Entidades</h1>
                    <PiCurrencyDollarSimple size={30} color='gray'/>
                </div>
            </div>
            <div onClick={() => setSelecionado(4)} className={`border-gray-500 border-b ${selecionado === 4 ? 'border-r  bg-[#d6d6d6]' : ''}`}>
                <div className='flex justify-around my-2'>
                    <h1 className='text-[20px] -mt-[2px]'>Teste de<br></br> Atendimento</h1>
                    <RiSparkling2Line size={30} color='gray' className='mt-3'/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SideBar