import React, { useRef, useState } from 'react'
import { FaBars, FaSearch } from 'react-icons/fa'
import { LuEye, LuEyeOff } from 'react-icons/lu'
import { PiFilePdf, PiPlusBold, PiTrash, PiXBold } from 'react-icons/pi'
import { motion, AnimatePresence } from 'framer-motion'
import { isMobile } from 'react-device-detect'

const Contextos = () => {
  const [contextos, setContextos] = useState([])
  const [termoBusca, setTermoBusca] = useState('') 
  const [contextoSelecionado, setContextoSelecionado] = useState(null)
  const [novaPergunta, setNovaPergunta] = useState('')
  const [novaResposta, setNovaResposta] = useState('')
  const [perguntasSimilares, setPerguntasSimilares] = useState([])
  const [novaPerguntaSimilar, setNovaPerguntaSimilar] = useState('')
  const [idEdicao, setIdEdicao] = useState(null) 
  const [perguntaEditada, setPerguntaEditada] = useState('')
  const [idRespostaEditada, setIdRespostaEditada] = useState(null) 
  const [respostaEditada, setRespostaEditada] = useState('')
  const [modalUpload, setModalUpload] = useState(false)
  const inputArquivosPDF = useRef(null)
  const [arquivos, setArquivos] = useState([])

  const token = localStorage.getItem('token')

  const fetchContextos = async () => {
    try {
      const response = await fetch('https://atendepramim.com:8082/api/assistente-virtual/pergunta-resposta/', {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`, 
        }
      })
      const data = await response.json()

      const contextosFormatados = data.map((item) => ({
        id: item.id_resposta,
        id_pergunta: item.id_pergunta,
        pergunta: item.pergunta,
        resposta: item.resposta,
        visibilidade: !item.ocultado,
      }))

      setContextos(contextosFormatados)
    } catch (error) {
      console.error('Erro ao buscar os contextos:', error)
    }
  }

  const fetchPerguntasSimilares = async (id) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/pergunta-similares/${id}/`, {
        method: 'GET',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`,
        }
      })

      if (response.ok) {
        const data = await response.json()
        setPerguntasSimilares(data)
      } else {
        console.error('Erro ao buscar perguntas similares:', response.statusText)
      }
    } catch (error) {
      console.error('Erro ao buscar perguntas similares:', error)
    }
  }

  fetchContextos()

  const handleFileUpload = (e) => {
    const newFiles = Array.from(e.target.files);
    const pdfFiles = newFiles.filter(file => file.type === 'application/pdf');
    setArquivos((prevFiles) => [...prevFiles, ...pdfFiles]);
  
    pdfFiles.forEach((file) => {
      uploadArquivo(file);
    });
  
    e.target.value = '';
  };

  const adicionarContexto = async () => {
    if (novaPergunta && novaResposta) {
      const novoContexto = {
        pergunta: novaPergunta,
        resposta: novaResposta,
      }

      try {
        const response = await fetch('https://atendepramim.com:8082/api/assistente-virtual/pergunta-resposta/', {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(novoContexto),
        })

        if (response.ok) {
          fetchContextos()
          setNovaPergunta('')
          setNovaResposta('')
        } else {
          console.error('Erro ao adicionar contexto:', response.statusText)
        }
      } catch (error) {
        console.error('Erro ao adicionar contexto:', error)
      }
    }
  }

  const toggleVisibilidade = async (id) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/alternar-ocultacao-resposta/${id}/`, {
        method: 'PATCH',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`,
        }
      })

      if (response.ok) {
        setContextos((prevContextos) =>
          prevContextos.map((contexto) =>
            contexto.id === id
              ? { ...contexto, visibilidade: !contexto.visibilidade }
              : contexto
          )
        )
      } else {
        console.error('Erro ao alternar visibilidade:', response.statusText)
      }
    } catch (error) {
      console.error('Erro ao alternar visibilidade:', error)
    }
  }

  const deletarContexto = async (id) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/respostas/${id}/`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`,
        }
      })

      if (response.ok) {
        fetchContextos()
      } else {
        console.error('Erro ao deletar contexto:', response.statusText)
      }
    } catch (error) {
      console.error('Erro ao deletar contexto:', error)
    }
  }

  const adicionarPerguntaSimilar = async (id) => {
    if (novaPerguntaSimilar) {
      try {
        const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/pergunta-similares/${id}/`, {
          method: 'POST',
          headers: {
            'accept': 'application/json',
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pergunta: novaPerguntaSimilar }),
        })

        if (response.ok) {
          fetchPerguntasSimilares(id)
          setNovaPerguntaSimilar('')
        } else {
          console.error('Erro ao adicionar pergunta similar:', response.statusText)
        }
      } catch (error) {
        console.error('Erro ao adicionar pergunta similar:', error)
      }
    }
  }

  const deletarPerguntaSimilar = async (id_pergunta) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/perguntas/${id_pergunta}/`, {
        method: 'DELETE',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`,
        }
      })

      if (response.ok) {
        setPerguntasSimilares((prevPerguntas) =>
          prevPerguntas.filter((pergunta) => pergunta.id_pergunta !== id_pergunta)
        )
      } else {
        console.error('Erro ao deletar pergunta similar:', response.statusText)
      }
    } catch (error) {
      console.error('Erro ao deletar pergunta similar:', error)
    }
  }

  const selecionarContexto = (contexto) => {
    setContextoSelecionado(contexto)
    fetchPerguntasSimilares(contexto.id)
  }

  const editarPergunta = (id, pergunta) => {
    setIdEdicao(id)
    setPerguntaEditada(pergunta)
  }

  const editarResposta = (id, resposta) => {
    setIdRespostaEditada(id)
    setRespostaEditada(resposta)
  }

  const salvarPerguntaSimilar = async (id_pergunta) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/perguntas/${id_pergunta}/`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pergunta: perguntaEditada }),
      })

      if (response.ok) {
        // Atualiza a lista de perguntas
        setPerguntasSimilares((prevPerguntas) =>
          prevPerguntas.map((pergunta) =>
            pergunta.id_pergunta === id_pergunta
              ? { ...pergunta, pergunta: perguntaEditada }
              : pergunta
          )
        )
        setIdEdicao(null)
        setPerguntaEditada('')
      } else {
        console.error('Erro ao atualizar pergunta similar:', response.statusText)
      }
    } catch (error) {
      console.error('Erro ao atualizar pergunta similar:', error)
    }
  }

  const salvarResposta = async (id_resposta) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/respostas/${id_resposta}/`, {
        method: 'PUT',
        headers: {
          'accept': 'application/json',
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resposta: respostaEditada }),
      })

      if (response.ok) {
        // Atualiza a lista de contextos
        setContextos((prevContextos) =>
          prevContextos.map((contexto) =>
            contexto.id === id_resposta
              ? { ...contexto, resposta: respostaEditada }
              : contexto
          )
        )
        setIdRespostaEditada(null)
        setRespostaEditada('')
      } else {
        console.error('Erro ao atualizar resposta:', response.statusText)
      }
    } catch (error) {
      console.error('Erro ao atualizar resposta:', error)
    }
  }

  const contextosFiltrados = contextos.filter(contexto =>
    contexto.pergunta.toLowerCase().includes(termoBusca.toLowerCase())
  )

  const perguntasSimilaresFiltradas = perguntasSimilares.filter(pergunta =>
    pergunta.pergunta.toLowerCase().includes(termoBusca.toLowerCase())
  )

  const uploadArquivo = async (arquivo) => {
    const formData = new FormData();
    formData.append('documento', arquivo);
  
    try {
      await fetch('https://atendepramim.com:8082/api/assistente-virtual/extrair_dados/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
        },
        body: formData,
      });
    } catch (error) {
      console.error('Erro na requisição:', error);
    }
  };

  if(isMobile){
    return (
      <div className='w-full h-full'>
        {modalUpload && 
          <div className='h-full w-[90%] absolute'>
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div onClick={() => { setModalUpload(false) }} className='fixed inset-0 bg-black bg-opacity-50 z-10' />
              <div className="bg-white p-5 rounded-lg z-20 w-[90%] " >
                <div className='flex flex-col items-center'>
                  <div className='mt-5'>
                    <h1 className='text-sm w-full'>
                      Insira arquivos de texto ou uma seção de FAQ contendo informações relevantes a sua empresa.<br/>
                      Esses dados permitem que o assistente automaticamente construa respostas.
                    </h1>
                  </div>

                  <div className='w-[400px] flex flex-col items-center justify-center'>
                    <div className='flex flex-col w-full p-5 justify-center items-center'>

                      <div className='mt-3 w-full flex items-center justify-center'>
                        <button
                          onClick={() => inputArquivosPDF.current.click()}
                          className="bg-[#228b22] flex justify-center items-center w-[90%] p-2 rounded-md text-white"
                        >
                          Escolher arquivo
                        </button>
                        <input
                          type="file"
                          ref={inputArquivosPDF}
                          onChange={handleFileUpload}
                          style={{ display: 'none' }}
                          accept="application/pdf"
                        />
                      </div>

                      {arquivos.length > 0 && (
                        <div className="mt-3 w-full max-h-44 overflow-y-auto overflow-x-hidden">
                          {arquivos.map((file, index) => (
                            <div key={index} className="flex items-center justify-between bg-gray-200 w-full p-2 rounded-lg mb-2">
                              <div className="flex items-center gap-2">
                                <PiFilePdf size={30} className="text-red-400" />
                                <span className="truncate max-w-xs">{file.name}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        }
        <div className='bg-white rounded-lg w-full h-full '>
          <div className='flex flex-col px-5 py-1 h-full'>
            <h1 className='font-semibold text-[20px]'>Contextos</h1>
  
            <div className="flex flex-col w-full relative mt-1">
              <input
                id="termo-busca"
                className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                placeholder="Buscar"
                onChange={(e) => setTermoBusca(e.target.value)}
              />
              <span className="absolute mt-[8px] ml-2 text-gray-400">
                <FaSearch size={20} />
              </span>
            </div>
  
            <div>
              <div className='flex gap-5 w-[100%] mt-1'>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Nova pergunta</h1>
                  <input
                    value={novaPergunta}
                    onChange={(e) => setNovaPergunta(e.target.value)}
                    className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
                  />
                </div>
                <div className='w-full'>
                  <h1 className='text-[15px]'>Nova resposta</h1>
                  <input
                    value={novaResposta}
                    onChange={(e) => setNovaResposta(e.target.value)}
                    className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
                  />
                </div>
                <div className='flex items-end gap-2'>
                  <button
                    onClick={adicionarContexto}
                    className='bg-[#228b22] text-white text-sm w-full p-2 rounded-lg justify-center items-center'
                  >
                    <PiPlusBold size={20}/>
                  </button>
                  <button
                    onClick={() => setModalUpload(true)}
                    className='bg-[#228b22] text-white text-sm w-full p-2 rounded-lg justify-center items-center'
                  >
                    <PiFilePdf size={20}/>
                  </button>
                  {/* 
                  */}
                </div>
              </div>
            </div>
  
            <div className='overflow-auto h-full'>
              <AnimatePresence>
                {!contextoSelecionado ? (
                  contextos.map((contexto) => (
                    <div key={contexto.id} className='flex w-[98%] border border-gray-400 p-2 mt-2 rounded-md'>
                      <div className='flex items-center'>
                        <FaBars onClick={() => selecionarContexto(contexto)} size={40} className='p-1 ml-4 cursor-pointer' />
                        <div className='border-2 border-gray-300 rounded-md mx-2 h-full'/>
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[15px] ml-2 text-gray-500'>
                          {contexto.resposta}
                        </h1>
                        <div className='border-2 border-gray-300 rounded-md mx-2'/>
                      </div>
                      <div className='flex flex-col gap-2 mx-3 cursor-pointer'>
                        {contexto.visibilidade ? (
                          <LuEye size={30} color='green' onClick={() => toggleVisibilidade(contexto.id)} />
                        ) : (
                          <LuEyeOff size={30} color='gray' onClick={() => toggleVisibilidade(contexto.id)} />
                        )}
                        <PiTrash size={30} color='red' onClick={() => deletarContexto(contexto.id)}/>
                      </div>
                    </div>
                  ))
                ) : (
                  <motion.div
                    key="painel"
                    className='h-[98%] relative'
                    initial={{ height: 0, opacity: 1 }}
                    animate={{ height: '98%', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className='absolute border-[1px] mt-[80px] w-[100%] border-gray-500 rounded-md' />
                    <div className='flex flex-col h-[98%] w-full border border-gray-400 p-2 mt-2 rounded-md'>
                      <div className='flex'>
                        <div className='flex items-center'>
                          <PiXBold onClick={() => setContextoSelecionado(null)} size={50} className='p-1 ml-2 cursor-pointer' />
                          <div className='border-2 border-gray-300 rounded-md mx-2 h-full'/>
                        </div>
                        <div className='flex w-full justify-between'>
                          <h1 className='flex items-center text-[15px] ml-2 text-gray-500'>
                            {contextoSelecionado.resposta}
                          </h1>
                          <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                        </div>
 
                        <div className='flex flex-col gap-2 mx-3 cursor-pointer'>
                          {contextoSelecionado.visibilidade ? (
                            <LuEye size={30} color='green' onClick={() => toggleVisibilidade(contextoSelecionado.id)} />
                          ) : (
                            <LuEyeOff size={30} color='gray' onClick={() => toggleVisibilidade(contextoSelecionado.id)} />
                          )}
                          <PiTrash size={30} color='red' onClick={() => deletarContexto(contextoSelecionado.id)}/>
                        </div>
                      </div>
  
                      <div>
                        <div className='flex  gap-5 w-[100%] mt-3'>
                          <div className='w-[80%]'>
                            <h1>Nova pergunta / tema similar</h1>
                            <input
                              value={novaPerguntaSimilar}
                              onChange={(e) => setNovaPerguntaSimilar(e.target.value)}
                              className="border mt-1 border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none" 
                              placeholder='Nova pergunta / tema similar' 
                            />
                          </div>
                          <div className='flex items-end'>
                            <button
                              onClick={() => adicionarPerguntaSimilar(contextoSelecionado.id)}
                              className='bg-[#228b22] text-white text-sm w-full p-2 rounded-lg justify-center items-center'
                            >
                              <PiPlusBold size={20}/>
                            </button>
                          </div>

                        </div>
                      </div>
  
                      <div className='flex gap-5 h-full mt-4 overflow-auto'>
                        <div className='w-full h-full flex flex-col'>
                          <div className='w-full h-full border border-gray-300 rounded-md p-2 resize-none overflow-auto'>
                          { 
                            perguntasSimilaresFiltradas.map((pergunta) => (
                              <div key={pergunta.id_pergunta} className='flex items-center gap-4 border w-fit border-gray-500 rounded-md p-2 mb-2'>
                                <h1>{pergunta.pergunta}</h1>
                                <div className='flex'>
                                  <PiTrash size={30} color='red' onClick={() => deletarPerguntaSimilar(pergunta.id_pergunta)} />
                                </div>
                              </div>
                          ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='relative px-8 py-7 w-full h-[80vh]'>
      {modalUpload && 
        <div className='h-full absolute'>
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div onClick={() => { setModalUpload(false) }} className='fixed inset-0 bg-black bg-opacity-50 z-10' />
            <div className="bg-white p-5 rounded-lg z-20" >
              <div className='flex flex-col items-center w-[450px]'>
                <div className='mt-5'>
                  <h1>
                    Para otimizar o assistente virtual, insira arquivos de texto ou uma seção de FAQ contendo informações relevantes a sua empresa.<br/>
                    Esses dados permitem que o assistente automaticamente construa respostas.
                  </h1>
                </div>

                <div className='w-[400px] flex flex-col items-center justify-center'>
                  <div className='flex flex-col w-full p-5 justify-center items-center'>
                    <h1 className='font-bold text-[15px] text-center'>Insira arquivos PDF relacionados à sua empresa</h1>

                    <div className='mt-3 w-full'>
                      <button
                        onClick={() => inputArquivosPDF.current.click()}
                        className="bg-[#228b22] flex justify-center items-center w-full p-2 rounded-md text-white"
                      >
                        Escolher arquivo
                      </button>
                      <input
                        type="file"
                        ref={inputArquivosPDF}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        accept="application/pdf"
                      />
                    </div>

                    {arquivos.length > 0 && (
                      <div className="mt-3 w-full max-h-44 overflow-y-auto overflow-x-hidden">
                        {arquivos.map((file, index) => (
                          <div key={index} className="flex items-center justify-between bg-gray-200 w-full p-2 rounded-lg mb-2">
                            <div className="flex items-center gap-2">
                              <PiFilePdf size={30} className="text-red-400" />
                              <span className="truncate max-w-xs">{file.name}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      }
      <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-full h-full'>
        <div className='flex flex-col px-5 py-3 h-[100%]'>
          <h1 className='font-semibold text-[32px]'>Contextos</h1>

          {/* Campo de busca */}
          <div className="flex flex-col w-full relative mt-1">
            <input
              id="termo-busca"
              className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-10 py-1 h-9 resize-none focus:outline-none"
              placeholder="Buscar"
              onChange={(e) => setTermoBusca(e.target.value)}
            />
            <span className="absolute mt-[8px] ml-2 text-gray-400">
              <FaSearch size={20} />
            </span>
          </div>

          {/* Novo contexto */}
          <div className='flex gap-5 w-[100%] mt-1'>
            <div className='w-full'>
              <h1>Nova pergunta / tema</h1>
              <input
                value={novaPergunta}
                onChange={(e) => setNovaPergunta(e.target.value)}
                className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
              />
            </div>
            <div className='w-full'>
              <h1>Nova resposta / contexto</h1>
              <input
                value={novaResposta}
                onChange={(e) => setNovaResposta(e.target.value)}
                className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
              />
            </div>
            <div className='w-full flex gap-2'>
              <div className='w-full'>
                <h1 className='text-white'>.</h1>
                <button
                  onClick={adicionarContexto}
                  className='bg-[#228b22] text-white w-full p-2 rounded-lg justify-center items-center'
                >
                  Adicionar contexto
                </button>
              </div>
              <button
                onClick={() => setModalUpload(true)}
                className='bg-[#228b22] text-white p-1 rounded-lg justify-center items-center'
              >
                <PiFilePdf size={50}/>
              </button>
              {/*
              */}
            </div>
          </div>

          {/* Lista de perguntas similares */}
          <div className='overflow-auto h-full'>
            <AnimatePresence>
              {!contextoSelecionado ? (
                contextosFiltrados.map((contexto) => (
                  <div key={contexto.id} className='flex w-full border border-gray-400 p-2 mt-2 rounded-md'>
                    <div className='flex'>
                      <FaBars onClick={() => selecionarContexto(contexto)} size={40} className='p-1 ml-4 mt-[6px] cursor-pointer' />
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex w-[30%] justify-between'>
                      <h1 className='flex items-center text-[20px] w-[300px] ml-2 text-gray-500 truncate'>
                        {contexto.pergunta}
                      </h1>
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex justify-between'>
                      {idRespostaEditada === contexto.id ? (
                        <input
                          value={respostaEditada}
                          onChange={(e) => setRespostaEditada(e.target.value)}
                          onBlur={() => salvarResposta(contexto.id)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') salvarResposta(contexto.id)
                          }}
                          className="border border-gray-400 bg-gray-100 rounded-lg pl-2 py-1 h-9 resize-none focus:outline-none w-[36vw] 2xl:w-[48vw]"
                        />
                      ) : (
                        <h1 onClick={() => editarResposta(contexto.id, contexto.resposta)} className='flex items-center text-[20px] ml-2 text-gray-500 cursor-pointer w-[36vw] 2xl:w-[48vw] truncate'>
                          {contexto.resposta}
                        </h1>
                      )}
                      <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                    </div>
                    <div className='flex gap-2 mx-3 cursor-pointer'>
                      {contexto.visibilidade ? (
                        <LuEye size={50} color='green' onClick={() => toggleVisibilidade(contexto.id)} />
                      ) : (
                        <LuEyeOff size={50} color='gray' onClick={() => toggleVisibilidade(contexto.id)} />
                      )}
                      <PiTrash size={50} color='red' onClick={() => deletarContexto(contexto.id)} />
                    </div>
                  </div>
                ))
              ) : (
                <motion.div
                  key="painel"
                  className='h-[98%] relative'
                  initial={{ height: 0, opacity: 1 }}
                  animate={{ height: '98%', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className='absolute border-[1px] mt-[70px] w-[100%] border-gray-500 rounded-md' />
                  <div className='flex flex-col h-[98%] w-full border border-gray-400 p-2 mt-2 rounded-md'>
                    <div className='flex'>
                      <div className='flex'>
                        <PiXBold onClick={() => setContextoSelecionado(null)} size={50} className='p-1 ml-2 mt-[6px] cursor-pointer' />
                        <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[20px] ml-2 text-gray-500'>
                          {contextoSelecionado.pergunta}
                        </h1>
                        <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                      </div>
                      <div className='flex w-full justify-between'>
                        <h1 className='flex items-center text-[20px] ml-2 text-gray-500'>
                        {contextoSelecionado.resposta}
                        </h1>
                        <div className='border-2 border-gray-300 rounded-md mx-2'></div>
                      </div>
                      <div className='flex gap-2 mx-3 cursor-pointer'>
                        {contextoSelecionado.visibilidade ? (
                          <LuEye size={50} color='green' onClick={() => toggleVisibilidade(contextoSelecionado.id)} />
                        ) : (
                          <LuEyeOff size={50} color='gray' onClick={() => toggleVisibilidade(contextoSelecionado.id)} />
                        )}
                        <PiTrash size={50} color='red' onClick={() => deletarContexto(contextoSelecionado.id)} />
                      </div>
                    </div>

                    <div>
                      <div className='flex gap-5 w-[100%] mt-3'>
                        <div className='w-[80%]'>
                          <h1>Nova pergunta / tema similar</h1>
                          <input 
                            value={novaPerguntaSimilar}
                            onChange={(e) => setNovaPerguntaSimilar(e.target.value)}
                            className="border mt-1 border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none" 
                            placeholder='Nova pergunta / tema similar' 
                          />
                        </div>
                        <div className='w-[20%]'>
                          <h1 className='text-white'>.</h1>
                          <button 
                            onClick={() => adicionarPerguntaSimilar(contextoSelecionado.id)}
                            className='bg-[#228b22] text-white w-full p-2 rounded-lg justify-center items-center'
                          >
                            Adicionar pergunta similar
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className='flex gap-5 h-full mt-4 overflow-auto'>
                      <div className='w-full h-full flex flex-col'>
                        <h1 className='text-xl mb-2 -mt-2'>Perguntas/temas similares</h1>
                        <div className='w-full h-full border border-gray-300 rounded-md p-2 overflow-auto'>
                          { 
                            perguntasSimilaresFiltradas.map((pergunta) => (
                              <div key={pergunta.id_pergunta} className='flex items-center gap-4 border w-fit border-gray-500 rounded-md p-2 mb-2'>
                                {idEdicao === pergunta.id_pergunta ? (
                                  <input
                                    value={perguntaEditada}
                                    onChange={(e) => setPerguntaEditada(e.target.value)}
                                    onBlur={() => salvarPerguntaSimilar(pergunta.id_pergunta)}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') salvarPerguntaSimilar(pergunta.id_pergunta)
                                    }}
                                    className="border border-gray-400 bg-gray-100 rounded-lg w-full pl-2 py-1 h-9 resize-none focus:outline-none"
                                  />
                                ) : (
                                  <h1 onClick={() => editarPergunta(pergunta.id_pergunta, pergunta.pergunta)} className="cursor-pointer">{pergunta.pergunta}</h1>
                                )}
                                <div className='flex'>
                                  <PiTrash size={30} color='red' onClick={() => deletarPerguntaSimilar(pergunta.id_pergunta)} />
                                </div>
                              </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contextos