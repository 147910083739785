import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import './index.css'

import Home from './root/paginas/homePage'
import Dashboard from './root/paginas/dashboard'
import Validacao from './root/paginas/validacao'
import Chat from './root/paginas/conversa'

function App() {

  const location = useLocation()

  useEffect(() => {
    const estiloSalvo = localStorage.getItem('estiloSelecionado')
    if (estiloSalvo) {
        const estilo = JSON.parse(estiloSalvo)
        applyStyle(estilo)
    }
    
  }, [location])

  const applyStyle = (estilo) => {
    document.documentElement.style.setProperty('--cor-principal', estilo.colors.cor_principal)
    document.documentElement.style.setProperty('--cor-secundaria', estilo.colors.cor_secundaria)
    document.documentElement.style.setProperty('--cor-atenuacao', estilo.colors.cor_atenuacao)
    document.documentElement.style.setProperty('--cor-fundo', estilo.colors.cor_fundo)
    document.documentElement.style.setProperty('--cor-texto', estilo.colors.cor_texto)
    document.documentElement.style.setProperty('--chat_cor_1', estilo.colors.chat_cor_1)
    document.documentElement.style.setProperty('--chat_cor_2', estilo.colors.chat_cor_2)
    document.documentElement.style.setProperty('--chat_cor_3', estilo.colors.chat_cor_3)
    document.documentElement.style.setProperty('--fundo_chat', estilo.colors.fundo_chat)
  }

  return (
    <main>
      <Routes>
        <Route >
          <Route path='/' element={<Home />} />

          <Route path="/chat/*" element={<Validacao />}>
          {/* Carrega a página de chat específica se a validação passar */}
          <Route path=":path" element={<Chat />} />
          
        </Route>
        </Route>

        <Route>
          <Route path='/dashboard' element={<Dashboard />} />
        </Route>
      </Routes>
    </main>
  );
}

export default App;