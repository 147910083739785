import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Estilos = ({ largura, idEstilo, setIdEstilo }) => {
    const [estilosDisponiveis, setEstilosDisponiveis] = useState([])
    const location = useLocation()

    useEffect(() => {
        const fetchEstilos = async () => {
            try {
                const response = await fetch('https://atendepramim.com:8082/api/estilos/')
                const estilos = await response.json()
                setEstilosDisponiveis(estilos)

                const estiloSalvo = localStorage.getItem('estiloSelecionado')
                if (estiloSalvo) {
                    const estilo = JSON.parse(estiloSalvo)
                    setIdEstilo(estilo)
                    applyStyle(estilo) 
                } else if (estilos.length > 0) {
                    const primeiroEstilo = estilos[0]
                    setIdEstilo(primeiroEstilo)
                    localStorage.setItem('estiloSelecionado', JSON.stringify(primeiroEstilo))
                    applyStyle(primeiroEstilo)
                }
            } catch (error) {
                console.error('Erro ao carregar os estilos:', error)
            }
        }

        fetchEstilos()
    }, [setIdEstilo])

    useEffect(() => {
        const estiloSalvo = localStorage.getItem('estiloSelecionado')
        if (estiloSalvo) {
            const estilo = JSON.parse(estiloSalvo)
            applyStyle(estilo)
        }
    }, [location])

    const applyStyle = (estilo) => {
        document.documentElement.style.setProperty('--cor-principal', estilo.colors.cor_principal)
        document.documentElement.style.setProperty('--cor-secundaria', estilo.colors.cor_secundaria)
        document.documentElement.style.setProperty('--cor-atenuacao', estilo.colors.cor_atenuacao)
        document.documentElement.style.setProperty('--cor-fundo', estilo.colors.cor_fundo)
        document.documentElement.style.setProperty('--cor-texto', estilo.colors.cor_texto)
        document.documentElement.style.setProperty('--chat_cor_1', estilo.colors.chat_cor_1)
        document.documentElement.style.setProperty('--chat_cor_2', estilo.colors.chat_cor_2)
        document.documentElement.style.setProperty('--chat_cor_3', estilo.colors.chat_cor_3)
        document.documentElement.style.setProperty('--fundo_chat', estilo.colors.fundo_chat)
    }

    const handleEstiloChange = (estiloIndex) => {
        const selectedEstilo = estilosDisponiveis[estiloIndex]

        applyStyle(selectedEstilo)
        setIdEstilo(selectedEstilo)
        localStorage.setItem('estiloSelecionado', JSON.stringify(selectedEstilo))
    }

    return (
        <div className='flex flex-col gap-4'>
            <div className='bg-white rounded-lg shadow-dark-bottom-right w-full' style={{ maxWidth: largura, height: '170px' }}>
                <div className='flex flex-col px-8 py-6 h-full'>
                    <h1 className='font-bold text-[22px]'>Estilos</h1>
                    <div className="flex gap-2 h-full overflow-auto">
                        {estilosDisponiveis.map((estilo, index) => (
                            <div
                                key={index}
                                onClick={() => handleEstiloChange(index)}
                                className={`flex relative items-center cursor-pointer mt-2 rounded-full`}
                            >
                                <div className={` ${estilo.id_estilo === idEstilo?.id_estilo ? 'border-[4px] border-collapse border-[#228b22] shadow-dark-bottom-right' : ''} absolute h-[68px] w-[68px] flex items-center cursor-pointer rounded-full`}/>
                                <div
                                    className='w-8 h-16 rounded-l-full'
                                    style={{ backgroundColor: estilo.colors.cor_principal, }}
                                />
                                <div
                                    className='w-8 h-16 rounded-r-full '
                                    style={{ backgroundColor: estilo.colors.cor_secundaria, }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Estilos
