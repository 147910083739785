import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PiArrowLeftBold, PiArrowRightBold, PiCheck, PiCircleFill, PiFilePdf, PiX, PiXBold } from 'react-icons/pi'
import { motion, AnimatePresence } from 'framer-motion'
import FotoPerfil from './fotoPerfil'
import Estilos from './estilos'
import { RxPencil1 } from 'react-icons/rx'
import { FaSpinner } from 'react-icons/fa'

function CadastroMobile({ setShowCadastroMobile }) {
  const navigate = useNavigate()
  const inputArquivosPDF = useRef(null)
  const [etapa, setEtapa] = useState(1)
  const [fotoPerfil, setFotoPerfil] = useState(null)
  const [blobImagem, setBlobImagem] = useState(null)
  const [arquivo, setArquivo] = useState(null)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [nomeEmpresa, setNomeEmpresa] = useState('')
  const [email, setEmail] = useState('')
  const [whatsapp, setWhatsapp] = useState('')
  const [instagram, setInstagram] = useState('')
  const [facebook, setFacebook] = useState('')
  const [nomeAssistente, setNomeAssistente] = useState('')
  const [idEstilo, setIdEstilo] = useState(1)
  const [estagioAssistente, setEstagioAssistente] = useState(0)
  const [cadastrando, setCadastrando] = useState(false)
  const [mensagens, ] = useState([
    {"texto": "Escolha um rosto pra mim"},
    {"texto": "Agora escolha meu nome"},
    {"texto": "Escolha a cor tema"},
  ])

  const token = localStorage.getItem('token')

  const handleImagemUpload = (imagemCortada, blobImagemCortada) => {
    setFotoPerfil(imagemCortada)
    setBlobImagem(blobImagemCortada)
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0]
    if (file && file.type === 'application/pdf') {
      setArquivo(file)
    }
    e.target.value = ''
  }

  const removeFile = () => {
    setArquivo(null)
  }

  const handleLogin = async (login, senha) => {
    try {
      const response = await fetch('https://atendepramim.com:8082/api-token-auth/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: login.toLowerCase(),
          password: senha,
        }),
      })

      if (response.ok) {
        const resposta = await response.json()
        localStorage.setItem('token', resposta.token)

        try {
          const response = await fetch(`https://atendepramim.com:8082/api/usuarios/${login}/`, {
            method: 'GET',
          })
          if (response.ok) {
            const resposta = await response.json()
            console.log(resposta)
            console.log(JSON.stringify(resposta))
            localStorage.setItem('conta', JSON.stringify(resposta)) 
          } else {
            console.error('Erro na resposta da API:', response.statusText)
          }
        } catch (error) {
          console.error('Erro ao fazer a requisição:', error)
        }

        navigate('/dashboard')
      }
    } catch (error) {
      console.error('Falha ao realizar login:', error)
    }
  }

  const handleFinalizarCadastro = async () => {
    setCadastrando(true)

    const redesSociais = {
      whatsapp,
      instagram,
      facebook
    }

    const formData = new FormData()
    formData.append('username', username)
    formData.append('password', password)
    formData.append('nome_empresa', nomeAssistente)
    formData.append('email', email)
    formData.append('foto_perfil', blobImagem)
    formData.append('redes_sociais', JSON.stringify(redesSociais))
    formData.append('id_estilo', idEstilo.id_estilo)

    try {
      const response = await fetch('https://atendepramim.com:8082/api/usuarios/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
        },
        body: formData,
      })
      
      if(response.ok){
        setCadastrando(false)
        handleLogin(username, password)
        //setEtapa(4)
      }
    } catch (error) {
      console.error('Erro na requisição:', error)
    }

  }

  const pageVariants = {
    initial: { opacity: 0, x: 20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 40 },
  }

  return (
    <div className='h-full'>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div onClick={() => { setShowCadastroMobile(false) }} className='fixed inset-0 bg-black bg-opacity-50 z-10' />
        <div className="bg-white p-5 rounded-lg z-20 w-[90%]">
          <div className='flex flex-col'>
            <div className='flex justify-between'>
              <h1 className='text-[25px] font-bold'>Criar Conta</h1>
              <PiXBold onClick={() => { setShowCadastroMobile(false) }} size={30} className='m-2 cursor-pointer' />
            </div>

            <AnimatePresence mode="wait">
              {etapa === 1 && (
                <motion.div
                  key="etapa1"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <h1 className='font-bold mt-2'>Nome de Usuário</h1>
                  <input 
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder='Nome de Usuário' 
                    className='p-1 mt-1 w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' 
                  />
                  <h1 className='text-sm text-gray-400'>Nome para login</h1>

                  <div className='flex gap-10'>
                    <div className='flex flex-col w-full'>

                      <h1 className='font-bold mt-2'>Email</h1>
                      <input 
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder='Email' 
                        className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' 
                      />
                      <h1 className='text-xs text-red-300'>*O email é necessário para a recuperação de senha</h1>
                    </div>
                  </div>

                  <div className='flex gap-4'>
                    <div className='flex flex-col w-full'>
                      <h1 className='font-bold mt-2'>Senha</h1>
                      <input 
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='p-1 pl-2 mt-1 w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' 
                      />

                      <h1 className='font-bold mt-2'>Confirmação de senha</h1>
                      <input 
                        type='password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text' 
                      />
                    </div>
                  </div>

                  <div className='flex justify-end'>
                      {(password === confirmPassword) && password && confirmPassword && email && username ? (
                        <button onClick={() => setEtapa(2)} className='bg-[#228b22] font-bold text-xl text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                          <PiArrowRightBold size={20} />
                        </button>
                      ):(
                        <button disabled={true} className='bg-gray-500 font-bold text-xl text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                          <PiArrowRightBold size={20} />
                        </button>
                      )}
                  </div>
                </motion.div>
              )}

              {etapa === 2 && (
                <motion.div
                  key="etapa2"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <div className='flex gap-10 relative'>
                    <div className='flex flex-col items-center w-full rounded-b-xl shadow-dark-bottom-right'>
                      <div className='bg-cor_principal rounded-t-xl h-15 w-full'>
                        <div className='flex items-center'>
                          {fotoPerfil === null ?
                            (
                              <PiCircleFill color='white' size={90} className='ml-5'/>
                            ):(
                              <div className='relative flex items-center justify-center mr-2 cursor-pointer' onClick={() => (setEstagioAssistente(0))} >
                                <img src={fotoPerfil} alt='Perfil' className='ml-5 h-[90px] rounded-full p-2' />
                                <div className='absolute end-1 bottom-1 bg-[#337e71] p-1 rounded-full'>
                                  <RxPencil1 color='white' className='cursor-pointer'/>
                                </div>
                              </div>
                            )
                          }
                          { nomeAssistente.length > 0 ? (
                              <div className='relative flex items-center justify-center cursor-pointer' onClick={() => (setEstagioAssistente(1))} >
                                <h1 className='text-white text-2xl font-semibold mr-4 mb-2 z-20'>{nomeAssistente}</h1>
                                <div className='absolute end-0 bottom-0 p-1 rounded-full z-10'>
                                  <RxPencil1 color='white' className='cursor-pointer'/>
                                </div>
                              </div>
                            ):(
                              <h1 className='text-white text-2xl font-semibold'>{nomeAssistente}</h1>
                            )

                          }
                        </div>
                      </div>

                      <div className="flex-1 overflow-y-auto p-4 bg-fundo_chat h-full w-full shadow-2xl">                      
                          <div className={`flex mb-2 justify-start`}>
                            <div className="flex items-start">
                              <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] bg-chat_cor_1 text-cor_1`} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                <div style={{ whiteSpace: 'pre-wrap' }}>{mensagens[estagioAssistente].texto}</div>
                                <div className={`absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] bg_chat_tick rotate-[-40deg] bottom-[-5px] -ml-[15px]`} />
                              </div>
                            </div>
                          </div>
                          
                          {estagioAssistente === 0 &&
                            <div className='flex flex-col items-center'>
                              <div className=''>
                                <FotoPerfil onImagemUpload={handleImagemUpload} imagem={fotoPerfil} setEstagioAssistente={setEstagioAssistente} />                              
                              </div>
                            </div>
                          }
                          {estagioAssistente === 1 &&
                            <div className='flex flex-col items-center'>
                              <div className='flex mt-[10%] items-center justify-center gap-5'>
                                <input onChange={(e) => setNomeAssistente(e.target.value)} value={nomeAssistente} placeholder='Nome do assistente' className='p-4 my-3 w-full bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg text-xl shadow-xl' />
                                {nomeAssistente ? 
                                  (
                                    <div onClick={() => (setEstagioAssistente(2))} className='bg-[#228b22] p-2 rounded-xl cursor-pointer shadow-xl'>
                                      <PiCheck color='white' size={20}/>
                                    </div>
                                  ):(
                                    <div className='bg-gray-400 p-2 rounded-xl cursor-pointer shadow-xl'>
                                      <PiCheck color='white' size={20}/>
                                    </div>
                                  )
                                }
                              </div>
                            </div>                            
                          }
                          {estagioAssistente === 2 &&
                            <div className='flex flex-col items-center'>
                              <div className='mt-[10%]'>
                                <Estilos 
                                  idEstilo={idEstilo}
                                  setIdEstilo={setIdEstilo}
                                  largura={'350px'} 
                                />                              
                              </div>
                            </div>                            
                          }
                      </div>

                      <div className="p-3 flex flex-col w-full border-t border-gray-400 bg-white rounded-b-xl relative">
                        <div className='flex gap-4'>
                          <button onClick={() => setEtapa(1)} className='bg-[#228b22] font-bold text-xl text-white w-full h-14 p-2 mt-4 rounded-lg flex justify-center items-center'>
                            <PiArrowLeftBold size={40} />
                          </button>

                          { fotoPerfil && nomeAssistente.length > 0 ?
                            (
                              <button onClick={() => setEtapa(3)} className='bg-[#228b22] font-bold text-xl text-white w-full h-14 p-2 mt-4 rounded-lg flex justify-center items-center'>
                                <PiArrowRightBold size={40} />
                              </button>
                            ):(
                              <button disabled={true} className='bg-gray-500 font-bold text-xl text-white w-full h-14 p-2 mt-4 rounded-lg flex justify-center items-center'>
                                <PiArrowRightBold size={40} />
                              </button>                            
                            )
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </motion.div>
              )}

              {etapa === 3 && (
                <motion.div
                  key="etapa3"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <h1 className='font-bold mt-2'>WhatsApp</h1>
                  <input 
                    value={whatsapp}
                    type='tel'
                    onChange={(e) => setWhatsapp(e.target.value)}
                    placeholder="(xx) xxxxx-xxxx" 
                    pattern="\(\d{2}\) \d{5}-\d{4}"
                    className='p-1 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg w-full' 
                  />

                  <h1 className='font-bold mt-2'>Instagram</h1>
                  <input 
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    placeholder="@contatoInsta" 
                    className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg w-full' 
                  />

                  <h1 className='font-bold mt-2'>Facebook</h1>
                  <input 
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                    placeholder='Facebook' 
                    className='p-1 pl-2 mt-1 bg-gray-100 placeholder-gray-500 border-2 border-gray-300 rounded-lg w-full' 
                  />

                  <div className='flex justify-between w-full gap-5'>
                    <button 
                      onClick={() => setEtapa(2)} 
                      className='bg-[#228b22] font-bold text-xl text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                      <PiArrowLeftBold size={20} />
                    </button>

                    {cadastrando ? 
                        (
                          <div className='bg-[#228b22] font-bold text-sm text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                            <FaSpinner className="animate-spin mr-2" />
                          </div>
                        ):(
                          <button onClick={() => handleFinalizarCadastro()} className='bg-[#228b22] font-bold text-sm text-white w-[40%] h-10 p-2 mt-2 rounded-lg flex justify-center items-center'>
                            Pular Etapa
                          </button>
                        )
                      }

                  </div>
                </motion.div>
              )}

              {etapa === 4 && (
                <motion.div
                  key="etapa4"
                  variants={pageVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.5 }}
                >
                  <div className='flex flex-col items-center w-full'>
                    <div className='mt-5'>
                      <h1 className='text-[3vw]'>
                        Para otimizar o assistente virtual, insira arquivos de texto ou uma seção de FAQ contendo informações relevantes a sua empresa.<br/>
                        Esses dados permitem que o assistente automaticamente construa respostas.
                      </h1>
                    </div>

                    <div className=' flex flex-col items-center justify-center'>
                      <div className='flex flex-col w-full p-5 justify-center items-center'>
                        <h1 className='font-bold text-[3vw] text-center'>Insira arquivos PDF relacionados à sua empresa</h1>

                        <div className='mt-3 w-full'>
                          <button
                            onClick={() => inputArquivosPDF.current.click()}
                            className="bg-[#228b22] hover:bg-green-500 flex justify-center items-center w-full text-[3vw] p-2 rounded-md text-white"
                          >
                            {arquivo ? 'Alterar arquivo PDF' : 'Escolher arquivo PDF'}
                          </button>
                          <input
                            type="file"
                            ref={inputArquivosPDF}
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            accept="application/pdf"
                          />
                        </div>

                        {arquivo && (
                          <div className="flex items-center justify-between mt-2 bg-gray-200 w-full p-2 rounded-lg">
                            <div className="flex items-center gap-2">
                              <PiFilePdf size={20} className="text-red-400" />
                              <span className="truncate">{arquivo.name}</span>
                            </div>
                            <PiX size={25} className="text-red-500 cursor-pointer" onClick={removeFile} />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='flex w-full justify-between gap-5'>
                        <button onClick={() => handleLogin(username, password)} className='bg-[#228b22] font-bold text-white w-full p-2 rounded-lg justify-center items-center'>
                            Acessar conta
                        </button>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CadastroMobile