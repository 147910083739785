import { useEffect, useState } from 'react'
import { useLocation, Outlet, Navigate } from 'react-router-dom'

const Validacao = () => {
  const location = useLocation()
  const path = location.pathname.split('/chat/')[1] // extrai a parte depois de "/chat/"

  const [validado, setValidado] = useState(false)
  const [loading, setLoading] = useState(true)

  const realizarValidacao = async () => {
    try {

      const response = await fetch(`https://atendepramim.com:8082/api/usuarios/${path}`)
      const resposta = await response.json()
      
      if(JSON.stringify(localStorage.getItem('conta')) !== JSON.stringify(('conta', JSON.stringify({ username: resposta.username, nome_empresa: resposta.nome_empresa, id_estilo: resposta.id_estilo, foto_perfil: resposta.foto_perfil, })))){

        localStorage.setItem('conta', JSON.stringify({
          username: resposta.username,
          nome_empresa: resposta.nome_empresa,
          id_estilo: resposta.id_estilo,
          foto_perfil: resposta.foto_perfil,
        }))
        
        const respose_2 = await fetch('https://atendepramim.com:8082/api/estilos/')
        const estilos = await respose_2.json()
        
        for (let i = 0; estilos[i]; i++) {
          if (estilos[i].id_estilo === resposta.id_estilo) {
            localStorage.setItem('estiloSelecionado', JSON.stringify(estilos[i]))
            break
          }
        }

        window.location.reload()

      }

      if (response.ok) {
        setValidado(true)

      } else {
        setValidado(false)
      }
    } catch (error) {
      setValidado(false)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    realizarValidacao()
  }, [])

  if (loading) {
    return <div></div>
  }

  return validado ? <Outlet /> : <Navigate to="/" replace />
}

export default Validacao