import React, { useEffect, useRef, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { PiCircleFill } from 'react-icons/pi'
import { motion, AnimatePresence } from 'framer-motion'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { isMobile } from 'react-device-detect'

const Conversas = () => {
  const [chats, setChats] = useState([''])
  const [conversaSelecionada, setConversaSelecionada] = useState(null)
  const [animacaoAtiva, setAnimacaoAtiva] = useState(false)
  const [mensagens, setMensagens] = useState([])
  const [modalEditar, setModalEditar] = useState(null)
  const [tipoConversa, setTipoConversa] = useState(1)

  const mensagensEndRef = useRef(null)

  const token = localStorage.getItem('token')

  const handleTipoConversaChange = (tipo) => {
    setTipoConversa(tipo)
    setConversaSelecionada(null)
    setAnimacaoAtiva(true)
  }

  const scrollFimConversa = () => {
    mensagensEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    if (conversaSelecionada) {
      const timer = setTimeout(() => {
        scrollFimConversa()
      }, 800)
  
      return () => clearTimeout(timer)
    }
  }, [mensagens, conversaSelecionada])

  const fetchChats = async () => {
    try {
      const response = await fetch('https://atendepramim.com:8082/api/assistente-virtual/chats/', {
        headers: {
          'Authorization': `Token ${token}`,
          'accept': 'application/json'
        }
      })
      const data = await response.json()
      setChats(data)
    } catch (error) {
      console.error('Erro ao buscar chats:', error)
    }
  }

  const fetchMensagens = async (identificador) => {
    try {
      const response = await fetch(`https://atendepramim.com:8082/api/assistente-virtual/chats/${identificador}/`, {
        headers: {
          'Authorization': `Token ${token}`,
          'accept': 'application/json'
        }
      })
      const data = await response.json()
      setMensagens(data)
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error)
    }
  }

  fetchChats()

  const handleChatSelect = (identificador) => {
    setConversaSelecionada(identificador)
    fetchMensagens(identificador)
    setAnimacaoAtiva(true)
  }

  const linkClicavel = (text) => {
    const urlRegex = /https?:\/\/[^\s]+/g
    return text.split(urlRegex).reduce((prev, current, i) => {
      const match = text.match(urlRegex)
      if (!match) return prev.concat(current)
      return prev.concat(
        <React.Fragment key={i}>
          {current}
          <a href={match[i]} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            {match[i]}
          </a>
        </React.Fragment>
      )
    }, [])
  }

  const calcularRows = (texto, limite) => {
    if (texto.split('\n').length < 2) {
        return Math.min((texto.length / 50) + 1, limite)
    } else {
        return Math.min(texto.split('\n').length, limite)
    }
  }

  const abrirModalEditar = (id_interacao, pergunta) => {
    let dados = {
        id_interacao: id_interacao,
        pergunta: pergunta,
        resposta: '',
        revisar: true
    }
    setModalEditar(dados)
  }

  const fecharModalTexto = () => {
    setModalEditar(null)
  }

  const handleInputChange = (field, value) => {
    setModalEditar(prevState => ({
        ...prevState,
        [field]: value
    }))
  }

  const atualizaPergunta = (pergunta, resposta, id_interacao) => {
    const novoItem = { pergunta, resposta }
    fetch(`https://atendepramim.com:8082/api/assistente-virtual/pergunta-resposta/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        body: JSON.stringify(novoItem)
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Erro ao atualizar a interação: ' + response.status)
        } else {
            // Atualiza o estado do componente para ocultar o botão de editar
            setMensagens(prevMensagens =>
                prevMensagens.map(mensagem =>
                    mensagem.id_interacao === id_interacao
                        ? { ...mensagem, revisar: false }
                        : mensagem
                )
            )
            fecharModalTexto()
        }
    })
    .catch(error => {
        console.log('Erro ao salvar alterações na pergunta.', error)
    })
  }

  if(isMobile){
    return (
      <div className='w-full h-full relative'>
        { modalEditar &&
          <div className='fixed inset-0 flex items-center justify-center z-20 '>
            <div onClick={fecharModalTexto} className='bg-black bg-opacity-50 fixed h-full w-full z-20'></div>
            <div className='bg-white rounded-lg min-h-[200px] p-6 w-[90%] z-30'>
                <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full'>
                    <textarea
                        id="editando-pergunta"
                        type="text"
                        className="w-full h-full justify-center items-center outline-none resize-none"
                        value={modalEditar.pergunta}
                        onChange={(e) => handleInputChange('pergunta', e.target.value)} 
                        rows={calcularRows(modalEditar.pergunta, 8)}
                        maxLength={250}
                    />
                    <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>PERGUNTA/TEMA</h1>
                    {modalEditar.pergunta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.pergunta.length} de 250</span></h1>}
                </div>
                <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full mt-7'>
                    <textarea
                        id="editando-resposta" 
                        type="text"
                        className="w-full h-full justify-center items-center outline-none resize-none"
                        value={modalEditar.resposta}
                        onChange={(e) => handleInputChange('resposta', e.target.value)}
                        rows={calcularRows(modalEditar.resposta, 8)}
                        maxLength={750}
                    />
                    <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>RESPOSTA/CONTEXTO</h1>
                    {modalEditar.resposta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.resposta.length} de 750</span></h1>}
                </div>
                <div className="flex justify-end items-end w-full gap-5">
                    <button onClick={fecharModalTexto}>
                        <h1 className='mt-4 bg-red-600 text-white px-4 py-2 rounded'>FECHAR</h1>
                    </button>
                    <button onClick={() => {atualizaPergunta(modalEditar.pergunta, modalEditar.resposta, modalEditar.id_interacao)}}>  
                        <h1 className='mt-4 bg-cor_principal text-white px-4 py-2 rounded'>ATUALIZAR</h1>
                    </button>
                </div>
            </div>
          </div>           
        }
        <div className='bg-white rounded-lg w-full h-full'>
          <div className='flex flex-col px-5 py-1 h-full'>
            <div className='absolute start-0 flex justify-around w-full shadow-dark-bottom-right'>
              <div className='mb-2 w-full relative'>
                <h1 onClick={() => handleTipoConversaChange(1)} className='font-semibold text-[16px] text-center'>
                  Conversas
                </h1>
                {tipoConversa === 1 && <div className='h-1 w-full bg-cor_principal absolute mt-1' />}
              </div>
  
              <div className='mb-2 w-full relative'>
                <h1 onClick={() => handleTipoConversaChange(2)} className='font-semibold text-[16px] text-center'>
                  Conversas com alerta
                </h1>
                {tipoConversa === 2 && <div className='h-1 w-full bg-cor_principal absolute mt-1' />}
              </div>
            </div>
  
            <div className="flex flex-col w-full relative mt-14">
              <input
                id="termo-busca"
                className="border border-gray-400 bg-gray-100 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
                placeholder="Buscar"
              />
              <span className="absolute mt-[8px] ml-2 text-gray-400">
                <FaSearch size={20} />
              </span>
            </div>
  
            <div className={`w-full overflow-auto h-full mt-1 ${animacaoAtiva ? 'overflow-hidden' : 'overflow-auto'}`}>
              <div className={`w-full h-[98%]`}>
                <AnimatePresence mode="wait">
                  {!conversaSelecionada ? (
                    <motion.div
                      className='flex gap-4'
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 50 }}
                      transition={{ duration: 0.5 }}
                      onAnimationStart={() => setAnimacaoAtiva(true)}
                      onAnimationComplete={() => setAnimacaoAtiva(false)}
                    >
                      {tipoConversa === 1 ? (
                        <div className='w-full'>
                          {chats.filter(item => item.quantidade_revisar === 0).map(({ identificador }) => (
                            <motion.div
                              key={identificador}
                              onClick={() => handleChatSelect(identificador)}
                              className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: 50 }}
                              transition={{ duration: 0.3 }}
                            >
                              <div className='flex'>
                                <PiCircleFill color='#482fdf' size={80} />
                                <div>
                                  <h1 className='flex items-center font-bold text-[15px] ml-2 mt-1'> Chat ID: <p className='font-semibold ml-2'>{identificador}</p> </h1>
                                </div>
                              </div>
                              <div className='flex justify-center items-center'>
                                <MdOutlineKeyboardArrowDown size={50} />
                              </div>
                            </motion.div>
                          ))}
                        </div>
                      ) : (
                        <div className='w-full'>
                          {chats.filter(item => item.quantidade_revisar > 0).map(({ identificador, quantidade_revisar }) => (
                            <motion.div
                              key={identificador}
                              onClick={() => handleChatSelect(identificador)}
                              className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                              initial={{ opacity: 0, y: 50 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: 50 }}
                              transition={{ duration: 0.3 }}
                            >
                              <div className='flex'>
                                <PiCircleFill color='#482fdf' size={80} />
                                <div>
                                  <h1 className='flex items-center font-bold text-[15px] ml-2 mt-1'> Chat ID: <p className='font-semibold ml-2'>{identificador}</p> </h1>
                                  <div className='flex items-center justify-center h-5 w-5 mt-2 ml-2 rounded-full bg-red-500 text-gray-100 text-sm'>{quantidade_revisar}</div>
                                </div>
                              </div>
                              <div className='flex justify-center items-center'>
                                <MdOutlineKeyboardArrowDown size={50} />
                              </div>
                              
                            </motion.div>
                          ))}
                        </div>
                      )} 
                    </motion.div>
                ) : (
                  <motion.div
                    key="detail-view"
                    className='h-[98%] w-full'
                    initial={{ height: 0, opacity: 1 }}
                    animate={{ height: '98%', opacity: 1 }}
                    exit={{ height: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    onAnimationStart={() => setAnimacaoAtiva(true)}
                    onAnimationComplete={() => setAnimacaoAtiva(false)}
                  >
                    <motion.div
                      className='flex flex-col relative h-[95%] w-full border border-gray-400 p-2 mt-2 rounded-md'
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div onClick={() => setConversaSelecionada(null)} className='flex flex-row justify-between w-full'>
                        <div className='flex'>
                          <PiCircleFill color='#482fdf' size={80} className='ml-5' />
                          <div>
                            <h1 className='flex items-center font-bold text-[20px] ml-2 mt-1'> Mensagens do Chat: <p className='font-semibold ml-2'>{conversaSelecionada}</p> </h1>
                          </div>
                        </div>
                      </div>
                      <div className='mt-4 overflow-y-auto overflow-x-hidden'>
                        {[...mensagens].reverse().map(({ id_interacao, pergunta, resposta }) => (
                          <div key={id_interacao}>
                            <div className="flex mb-2 w-full items-end justify-end">
                              <div className='flex'>
                                {resposta === 'Ainda não sei nada sobre isso.' && (
                                  <span className="flex items-center justify-center h-6 w-6 rounded-full bg-red-500 text-white cursor-pointer mr-2 mt-1" onClick={() => abrirModalEditar(id_interacao, pergunta)}>!</span>
                                )}
                                <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] bg-chat_cor_2 mr-2 text-left text-cor_2 `} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>{linkClicavel(pergunta)}</div>
                                    <div className={`absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] bg_chat_tick_2 mt-2 right-0 rotate-[40deg] bottom-[-5px] -mr-[7px]`} />
                                </div>
                              </div>
                            </div>
                            <div className="flex mb-2 w-full ">
                              <div>
                                <div className={`p-2 rounded-lg justify-end text-sm text-white relative max-w-[80vw] bg-chat_cor_1 ml-2 text-cor_1 `} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                      {linkClicavel(resposta)}
                                    </div>
                                    <div className={`absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] bg_chat_tick rotate-[-40deg] bottom-[-5px] -ml-[15px]`} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div ref={mensagensEndRef}></div>
                      </div>
                    </motion.div>
                  </motion.div>
                )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='px-8 py-7 w-full h-[85vh]'>
        { modalEditar &&
          <div className='fixed inset-0 flex items-center justify-center z-20 '>
            <div onClick={fecharModalTexto} className='bg-black bg-opacity-50 fixed h-full w-full z-20'></div>
            <div className='bg-white rounded-lg min-h-[200px] p-6 w-1/2 z-30'>
                <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full'>
                    <textarea
                        id="editando-pergunta"
                        type="text"
                        className="w-full h-full justify-center items-center outline-none resize-none"
                        value={modalEditar.pergunta}
                        onChange={(e) => handleInputChange('pergunta', e.target.value)} 
                        rows={calcularRows(modalEditar.pergunta, 8)}
                        maxLength={250}
                    />
                    <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>PERGUNTA/TEMA</h1>
                    {modalEditar.pergunta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.pergunta.length} de 250</span></h1>}
                </div>
                <div className='relative border border-cor_principal bg-white rounded-md p-2 w-full mt-7'>
                    <textarea
                        id="editando-resposta" 
                        type="text"
                        className="w-full h-full justify-center items-center outline-none resize-none"
                        value={modalEditar.resposta}
                        onChange={(e) => handleInputChange('resposta', e.target.value)}
                        rows={calcularRows(modalEditar.resposta, 8)}
                        maxLength={750}
                    />
                    <h1 className='absolute top-0 -mt-3 bg-white p-1 text-xs text-cor_principal'>RESPOSTA/CONTEXTO</h1>
                    {modalEditar.resposta.length > 0 && <h1 className='text-xs text-gray-600 absolute bottom-0'>Máximo de caracteres <span className='text-gray-600'>{modalEditar.resposta.length} de 750</span></h1>}
                </div>
                <div className="flex justify-end items-end w-full gap-5">
                    <button onClick={fecharModalTexto}>
                        <h1 className='mt-4 bg-red-600 text-white px-4 py-2 rounded'>FECHAR</h1>
                    </button>
                    <button onClick={() => {atualizaPergunta(modalEditar.pergunta, modalEditar.resposta, modalEditar.id_interacao)}}>  
                        <h1 className='mt-4 bg-cor_principal text-white px-4 py-2 rounded'>ATUALIZAR</h1>
                    </button>
                </div>
            </div>
          </div>           
        }
      <div className='bg-white rounded-lg border border-gray-300 shadow-dark-bottom-right w-full h-full'>
        <div className='flex flex-col px-5 py-3 h-full'>
          <h1 className='font-semibold text-[20px]'>Conversas</h1>

          <div className="flex flex-col w-full relative mt-1">
            <input
              id="termo-busca"
              className="border border-gray-400 bg-gray-100 rounded-md w-full pl-10 py-1 h-9 resize-none focus:outline-none"
              placeholder="Buscar"
            />
            <span className="absolute mt-[8px] ml-2 text-gray-400">
              <FaSearch size={20} />
            </span>
          </div>

          <div className={`w-full overflow-auto h-full mt-4 ${animacaoAtiva ? 'overflow-hidden' : 'overflow-auto'}`}>
            <div className={`w-full h-[98%]`}>
              <AnimatePresence mode="wait">
                {!conversaSelecionada ? (
                  <motion.div
                    className='flex gap-4'
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    transition={{ duration: 0.5 }}
                    onAnimationStart={() => setAnimacaoAtiva(true)}
                    onAnimationComplete={() => setAnimacaoAtiva(false)}
                  >
                    <div className='w-full'>
                      <h1 className='font-bold text-[32px]'>Conversas</h1>
                      {chats.filter(item => item.quantidade_revisar === 0).map(({ identificador }) => (
                        <motion.div
                          key={identificador}
                          onClick={() => handleChatSelect(identificador)}
                          className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 50 }}
                          transition={{ duration: 0.3 }}
                        >
                          <div className='flex'>
                            <div>
                              <h1 className='flex items-center font-bold text-[20px] ml-2 mt-1'> Chat ID: <p className='font-semibold ml-2 text-sm'>{identificador}</p> </h1>
                            </div>
                          </div>
                          <div className='flex justify-center items-center'>
                            <MdOutlineKeyboardArrowDown size={50} />
                          </div>
                        </motion.div>
                      ))}
                    </div>

                    <div className='w-full'>
                      <h1 className='font-bold text-[32px]'>Conversas com Alerta</h1>
                      {chats.filter(item => item.quantidade_revisar > 0).map(({ identificador, quantidade_revisar }) => (
                        <motion.div
                          key={identificador}
                          onClick={() => handleChatSelect(identificador)}
                          className='flex justify-between w-full border border-gray-400 p-2 mt-2 rounded-md'
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 50 }}
                          transition={{ duration: 0.3 }}
                        >
                          <div className='flex'>
                            <div>
                              <h1 className='flex items-center font-bold text-[20px] ml-2 mt-1'> Chat ID: <p className='font-semibold ml-2 text-sm'>{identificador}</p> </h1>
                              <div className='flex items-center justify-center h-5 w-5 mt-2 ml-2 rounded-full bg-red-500 text-gray-100 text-sm'>{quantidade_revisar}</div>
                            </div>
                          </div>
                          <div className='flex justify-center items-center'>
                            <MdOutlineKeyboardArrowDown size={50} />
                          </div>
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key="detail-view"
                    className='h-[98%] w-full'
                    initial={{ height: 0, opacity: 1 }}
                    animate={{ height: '98%', opacity: 1 }}
                    exit={{ height: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    onAnimationStart={() => setAnimacaoAtiva(true)}
                    onAnimationComplete={() => setAnimacaoAtiva(false)}
                  >
                    <motion.div
                      className='flex flex-col relative h-[95%] w-full border border-gray-400 p-2 mt-2 rounded-md'
                      initial={{ opacity: 1 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div onClick={() => setConversaSelecionada(null)} className='flex flex-row justify-between w-full'>
                        <div className='flex'>
                          <div>
                            <h1 className='flex items-center font-bold text-[20px] ml-2 mt-1'> Mensagens do Chat: <p className='font-semibold ml-2'>{conversaSelecionada}</p> </h1>
                          </div>
                        </div>
                        <div className='flex justify-end items-center'>
                          <MdOutlineKeyboardArrowUp size={50} />
                        </div>  
                      </div>
                      <div className='mt-4 overflow-y-auto overflow-x-hidden'>
                        {[...mensagens].reverse().map(({ id_interacao, pergunta, resposta }) => (
                          <div key={id_interacao}>
                            <div className="flex mb-2 w-full items-end justify-end">
                              <div className='flex'>
                                {resposta === 'Ainda não sei nada sobre isso.' && (
                                  <span className="flex items-center justify-center h-6 w-6 rounded-full bg-red-500 text-white cursor-pointer mr-2 mt-1" onClick={() => abrirModalEditar(id_interacao, pergunta)}>!</span>
                                )}
                                <div className={`p-2 rounded-lg text-sm text-white relative max-w-[80vw] bg-chat_cor_2 mr-2 text-left text-cor_2 `} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>{linkClicavel(pergunta)}</div>
                                    <div className={`absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-l-[12px] bg_chat_tick_2 mt-2 right-0 rotate-[40deg] bottom-[-5px] -mr-[7px]`} />
                                </div>
                              </div>
                            </div>
                            <div className="flex mb-2 w-full ">
                              <div>
                                <div className={`p-2 rounded-lg justify-end text-sm text-white relative max-w-[80vw] bg-chat_cor_1 ml-2 text-cor_1 `} style={{ wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                      {linkClicavel(resposta)}
                                    </div>
                                    <div className={`absolute w-0 h-0 border-t-[6px] border-t-transparent border-b-[6px] border-b-transparent border-r-[12px] bg_chat_tick rotate-[-40deg] bottom-[-5px] -ml-[15px]`} />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div ref={mensagensEndRef}></div>
                      </div>
                    </motion.div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Conversas