import React, { useEffect, useState } from 'react'
import Header from './ui/header'
import SideBar from './ui/menuLateral'
import NavBar from './ui/nav_bar'
import Conversas from './dashboard/conversas'
import Contexto from './dashboard/contextos'
import Entidade from './dashboard/entidades'
import Teste from './testeAtendimento'
import Perfil from './dashboard/perfil'
import { isMobile } from 'react-device-detect'

function Dashboard() {
  const [selecionado, setSelecionado] = useState(1)
  const [pagina, setPagina] = useState(1) 

  if (isMobile) {
    return (
      <div className="flex flex-col h-full">
        <header className="w-full">
          <Header 
            pagina={pagina}
            setPagina={setPagina}
          />
        </header>

        {pagina === 1 &&
          <div className="flex flex-1 bg-[#f4f4f4] w-full">
            <main className="w-[100vw] h-[82vh] border-t">
              {selecionado === 1 && <Conversas />}
              {selecionado === 2 && <Contexto />}
              {selecionado === 3 && <Entidade />}
              {selecionado === 4 && <Teste />}
            </main>
          </div>
        }
        {pagina === 2 &&
          <div className="flex flex-1 bg-[#f4f4f4] w-full">
            <div className="w-[100vw] 2xl:h-[90vh] xl:h-[85vh] px-4 border-t border-gray-500">
              <Perfil/>
            </div>
          </div>  
        }
        {pagina === 3 &&
          <div className="w-[100vw] px-4 border-t border-gray-500">
            in-dev
          </div>
        }
        <NavBar 
          selecionado={selecionado}
          setSelecionado={setSelecionado}
        />
      </div>
    )
  }

  return (
    <div className="flex flex-col min-h-screen">
      <header className="w-full">
        <Header 
          pagina={pagina}
          setPagina={setPagina}
        />
      </header>

      {pagina === 1 &&
        <div className="flex flex-1 bg-[#f4f4f4] w-full">
          <aside className="block w-[15vw] bg-gray-200">
            <SideBar 
              selecionado={selecionado}
              setSelecionado={setSelecionado}
            />
          </aside>

          <main className="w-[85vw] px-4 border-t border-gray-500">
            {selecionado === 1 && <Conversas />}
            {selecionado === 2 && <Contexto />}
            {selecionado === 3 && <Entidade />}
            {selecionado === 4 && <Teste />}
          </main>
        </div>
      }
      {pagina === 2 &&
        <div className="flex flex-1 bg-[#f4f4f4] w-full">
          <div className="w-[100vw] h-[85vh] px-4 border-t border-gray-500">
            <Perfil/>
          </div>
        </div>  
      }
    </div>
  )
}

export default Dashboard